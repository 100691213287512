<template>
  <div>
    <deleteAllServices v-if="services.length" :services="services" :callback="setDelete" />
    <addService :services="services" />
    <transition-group name="flixFadeIn" v-if="services.length">
    <div v-for="(service, index) in services" :key="service.ID" class="flix-list-group">
      <div class="flix-list-group-item flix-active">
        {{ $tc('message.service', 1) }} # {{ index + 1 }}
        <div class="flix-pull-right">
          <a href="#" @click.prevent="setRemoveService(index)" class="flix-html-a flix-text-danger"><flixIcon :id="'bin'" /></a>
        </div>
      </div>
      <div class="flix-list-group-item">
        <div class="flix-form-group">
          <flixIcon class="icon" :id="'handshake'" /> <input @change="setSaveService(index)" type="text" spellcheck="false" v-model="service.title" class="flix-form-control" />
        </div>
        <div class="flix-form-group">
          <flixIcon class="icon" :id="'info-sign'" /> <input @change="setSaveService(index)" type="text" spellcheck="false" v-model="service.description" class="flix-form-control" />
        </div>
        <div class="flix-row">
          <div class="flix-col-md-6">
            <div class="flix-form-group">
              <flixIcon class="icon" :id="'clock'" /> <select @change="setSaveService(index)" v-model="service.time" class="flix-form-control">
                <option v-for="time in Array(3).keys()" :key="'time_'+time + 'a'" :value="((time + 1 ) * 15)" v-html="((time + 1 )* 15) + ' ' + $t('message.minutes')"></option>
                <option :value="60" v-html="1 + ' ' + $t('message.hour')"></option>
                <option :value="90" v-html="90 + ' ' + $t('message.minutes')"></option>
                <option v-for="time in Array(23).keys()" :key="'time_'+time + 'b'" :value="((time + 2 ) * 60)" v-html="time + 2 + ' ' + $t('message.hours')"></option>
              </select>
            </div>
          </div>
          <div class="flix-col-md-6">
            <div class="flix-form-group">
              <flixIcon class="icon" :id="'euro'" /> <input type="number" @change="setSaveService(index)" spellcheck="false" v-model="service.price" class="flix-form-control" />
            </div>
          </div>
        </div>
        <div class="flix-form-group">
          <flixIcon class="icon" :id="'folder-open'"/> <h4 class="flix-html-h4" style="margin: 0; display: inline-block">{{ $tc('message.categories', 1) }}</h4>
        </div>
        <div class="flix-form-group">
          <serviceCategory :services="services" :index="index" />
        </div>
        <div class="flix-form-group">
          <flixIcon class="icon" :id="'camera'" /> <h4 class="flix-html-h4" style="margin: 0; display: inline-block">{{ $t('message.add', {name: $t('message.image')}) }}</h4>
        </div>
        <div class="flix-text-info flix-form-group"><flixIcon :id="'info-sign'" /> {{ $t('message.imagePlaceholder') }}</div>
        <div class="flix-form-group">
          <serviceImage :service="service" :index="index" :settings="{label: $t('message.add', {name: $t('message.image')})}"/>
        </div>
      </div>
    </div>
  </transition-group>
  </div>
</template>
<script>
export default {
  components: {
    addService () { return import('@/components/services/add') },
    serviceCategory () { return import('@/components/services/categories') },
    serviceImage () { return import('@/components/services/image') },
    deleteAllServices () { return import('@/components/services/deleteAll') }
  },
  props: {},
  data () {
    return {
      services: []
    }
  },
  methods: {
    setDelete () {
      this.services = []
    },
    setRemoveService (index) {
      this.services.splice(index, 1)
      this.$store.dispatch('setAddService', this.services)
    },
    setSaveService (index) {
      this.$store.dispatch('setChangeService', [index, this.services[index]])
    }
  },
  mounted () {
    this.$store.dispatch('getServices', function (services) {
      Object.keys(services).forEach(function (k) {
        if ((services[k].time * 1) < 15) {
          services[k].time = (services[k] * 1) * 60
        }
      })
      this.services = services
    }.bind(this))
  }
}
</script>
<style lang="sass" scoped>
  .icon
    font-size: 30px
    margin-right: 10px
  .flix-form-control
    display: inline-block
    width: calc(100% - 50px)
  .flix-active, .flix-active:hover
    background-color: lightgrey
    border: grey
    color: #717C80
</style>
